import * as React from 'react'
import Layout from '../../components/layout'

const EnergyPriorities = () => {
    return (
        <Layout pageTitle='What are Your Energy Priorities' nextBottomSlideBtn="Contact Us" nextBottomSlideBtnLink="/ContactForm" id="advisory">
            <div className="fullWidth">
                <div className="listCol listWhiteBg">
                    <ul style={{ animation: `fadeIn .75s ease-in-out forwards`, opacity: 0 }}>
                        <strong>Net-Zero Efforts</strong>
                        <li className="marginTop">Sustainability policy</li>
                        <li>Energy efficiency </li>
                        <li>Electrification</li>
                    </ul>
                    <div className="verticalLine" style={{ animation: `fadeIn .75s ease-in-out forwards 1s`, opacity: 0 }}></div>
                    <ul style={{ animation: `fadeIn .75s ease-in-out forwards 1s`, opacity: 0 }}>
                        <strong>Resilience</strong>
                        <li className="marginTop">Success planning</li>
                        <li>Energy storage</li>
                        <li>Microgrids</li>
                        <li>DER strategy</li>
                    </ul>
                    <div className="verticalLine" style={{ animation: `fadeIn .75s ease-in-out forwards 2s`, opacity: 0 }}></div>
                    <ul style={{ animation: `fadeIn .75s ease-in-out forwards 2s`, opacity: 0 }}>
                        <strong>Success Planning</strong>
                        <li className="marginTop">Resource coordination</li>
                        <li>Decision-making</li>
                        <li>Technology choices</li>
                        <li>Asset portfolio optimization</li>
                        <li>Project and risk management</li>
                        <li>Transaction support</li>
                    </ul>
                </div>
            </div>
        </Layout>
    )
}

export default EnergyPriorities